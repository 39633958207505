import React, { FC } from "react";
import { Button, ButtonSize, Heading, Text } from "@qwilr/kaleidoscope";
import background from "assets/technicolor.gif";

const Page404: FC = () => {
  return (
    <div className="page-404">
      <h1
        className="page-404__title"
        style={{
          backgroundImage: `linear-gradient(rgb(36, 54, 57, 0.5), rgb(36, 54, 57, 0.5)), url("${background}")`,
        }}
      >
        404
      </h1>
      <div className="page-404__text">
        <Heading align="center" strong level="2">
          Page not found
        </Heading>
        <Text className="page-404__description" size="l" align="center">
          The page you're looking for has either moved or doesn't exist.
        </Text>
        <Button size={ButtonSize.Large} href={() => "/"}>
          Back to homepage
        </Button>
      </div>
    </div>
  );
};

export default Page404;
